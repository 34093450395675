<template>
  <div id="controllerCompaniesManagment" v-if="areControllerCompaniesLoaded">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="
					container-fluid
					d-flex
					align-items-center
					justify-content-between
					flex-wrap flex-sm-nowrap
				"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5
            class="text-dark font-weight-bold mt-2 mb-2 mr-5 text-align-center"
          >
            {{ $t("admin.menu.companiesManagement.controller.title") }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.company", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
        </div>
        <!--end::Details-->
      </div>
    </div>
    <!--begin::Companies-->
    <div class="row">
      <b-col
        sm="12"
        md="6"
        xl="3"
        v-for="company in filtered"
        class="mb-6"
        :key="company.stakeholderId"
      >
        <b-card class="card border-hover-primary" no-body>
          <!--begin::Card header-->
          <div class="card-header border-0">
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <div class="symbol symbol-60 symbol-xl-90">
                <div class="symbol-label bg-transparent">
                  <v-lazy-image
                    v-if="company.logo"
                    class="max-h-100px max-w-200px"
                    :src="`${$baseUrl}${company.logo}`"
                    :src-placeholder="
                      require('@/assets/images/icons/loading.gif')
                    "
                  />
                  <img
                    v-else
                    class="min-h-100px max-w-200px"
                    :src="require('@/assets/images/icons/image.svg')"
                  />
                </div>
              </div>
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end:: Card header-->

          <!--begin:: Card body-->
          <div class="card-body pt-0 pb-0 text-center">
            <!--begin::Name-->
            <div class="font-size-h6 font-weight-bold text-gray-900">
              {{ company.name }}
            </div>
            <!--end::Name-->

            <!--begin::Description-->
            <!-- <p class="text-gray-500 font-weight-bold font-size-h6 mt-1">
              ({{ company.subdomain ? company.subdomain : "-" }})
            </p> -->
            <!--end::Description-->

            <!--begin::Info-->
            <div class="mb-2 mt-3 ml-auto">
              <!--begin::Due-->
              <div
                class="border border-gray-300 border-dashed rounded py-3 px-4 me-7 mb-2 w-100px mx-auto"
              >
                <div class="font-size-h7 text-gray-800 font-weight-bold">
                  {{ company.peopleCount }}
                  <span class="font-bold text-gray-500 ml-3">
                    <i class="fa fa-users"></i>
                  </span>
                </div>
              </div>
              <!--end::Due-->
            </div>
            <div class="mb-2 mt-3 mx-auto">
              <div class="rounded py-3 px-4 me-7 min-w-100px text-center">
                <b-button
                  size="sm"
                  variant="secondary"
                  @click="selectCompany(company)"
                  pill
                  class="btn font-weight-bolder mx-auto"
                >
                  {{ $t("tables.select") }}
                </b-button>
              </div>
            </div>
            <!--end::Info-->
          </div>
          <!--end:: Card body-->
        </b-card>
      </b-col>
    </div>
    <b-col cols="12" v-if="total < 1">
      <empty-search
        :description="$t(`tables.empty.description`)"
        :buttonText="$t(`tables.empty.button`)"
        :action="resetFilters"
      />
    </b-col>
    <!--end::Companies-->
  </div>
</template>

<script>
import store from "@/store";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { removeProfileData } from "@/utils/helpers";
import { getMimetype } from "@/utils/helpers";
import EmptySearch from "@/components/EmptySearch.component.vue";
import VLazyImage from "v-lazy-image/v2";

export default {
  name: "ControllerCompaniesManagement",
  components: {
    EmptySearch,
    VLazyImage
  },
  data() {
    return {
      search: "",
      companiesDetail: [],
      maxElements: 12,
      pageElements: 1,
      loaded: true
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Controller", [
      "companies",
      "companySelected",
      "areControllerCompaniesLoaded"
    ]),
    total() {
      let companies = this.companies;
      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        companies = _.filter(companies, company => {
          return regex.test(_.deburr(company.name));
        });
      }
      return _.size(companies);
    },
    filtered() {
      let companies = this.companies;
      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        companies = _.filter(companies, company => {
          return regex.test(_.deburr(company.name));
        });
      }

      return companies;
    }
  },
  methods: {
    ...mapActions("Controller", [
      "loadCompanySelected",
      "loadControllerCompanies"
    ]),
    ...mapActions("Company", ["loadGeneralDashboard"]),
    ...mapActions("Constants", ["loadConstants"]),
    callGetMimetype(base64) {
      if (base64) return getMimetype(base64);
    },
    updateLoaded(value) {
      this.loaded = value;
    },
    selectCompany(company) {
      removeProfileData();
      localStorage.setItem("managedCompanyId", company.stakeholderId);
      localStorage.setItem("companySelected", JSON.stringify(company));
      store.dispatch("Controller/setCompany", company);
    },
    resetFilters() {
      this.search = "";
    }
  },
  watch: {
    companySelected(value) {
      if (localStorage.getItem("managedCompanyId") && value != false) {
        this.loadCompanySelected();
        this.$router.push({ name: "ControllerDashboard" });
      }
    }
  },
  mounted() {}
};
</script>
